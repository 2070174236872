// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n9FaZoG\\+{align-items:center;background:var(--color-body);box-shadow:0 4px 20px rgba(0,0,0,.25);display:flex;justify-content:flex-start;padding:16px 0 40px}@media(min-width:947.98px){.n9FaZoG\\+{padding:48px 0 100px}}.rA\\+EZkV-{left:0;padding:1rem;position:fixed;right:0;top:0;z-index:200}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"header": "n9FaZoG+",
	"headerExpanded": "rA+EZkV-"
};
module.exports = ___CSS_LOADER_EXPORT___;
