// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yx9tClj9{background-color:var(--color-body);color:var(--color-text);overflow-x:hidden}.aV\\+HayPs,.Yx9tClj9{min-height:100vh}.aV\\+HayPs{flex-grow:1;margin:0 auto;max-width:1440px;padding:0 16px;width:100%}.aV\\+HayPs *{font-family:var(--landing-font)}@media(min-width:667.98px){.aV\\+HayPs{padding:0 24px}}@media(min-width:947.98px){.aV\\+HayPs{padding:0 32px}}._4lJP7S2A{opacity:0;pointer-events:none;position:absolute;-webkit-user-select:none;-moz-user-select:none;user-select:none;visibility:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "Yx9tClj9",
	"grid": "aV+HayPs",
	"asset": "_4lJP7S2A"
};
module.exports = ___CSS_LOADER_EXPORT___;
